import React from "react"
import { Link } from "gatsby"
import sectionStyles from "./scss/latest-posts.module.scss"

const Pagination = props => {
  const { numberOfPages, currentPage } = props
  const isFirst = currentPage === 1
  const isLast = currentPage === numberOfPages
  const isNotPaginated = isFirst & isLast
  const prevPageNum = currentPage - 1 === 1 ? `` : currentPage - 1
  const nextPageNum = currentPage + 1
  const prevPageLink = isFirst ? null : `/blog/${prevPageNum}/`
  const nextPageLink = isLast ? null : `/blog/${nextPageNum}/`

  return (
    <div className={sectionStyles.paginationControls}>
      {(!isFirst && (
        <Link to={prevPageLink} className={sectionStyles.button}>
          &#8592; Prev Page
        </Link>
      )) || <div />}
      {(!isNotPaginated && (
        <span>
          {currentPage}/{numberOfPages}
        </span>
      )) || <div />}
      {(!isLast && (
        <Link to={nextPageLink} className={sectionStyles.button}>
          Next Page &#8594;
        </Link>
      )) || <div />}
    </div>
  )
}

export default Pagination
