import React from "react"
import { graphql, Link } from "gatsby"
import { slugify } from "../util/utilityFunctions"
// Components
import Layout from "../components/layout"
import SEO from "../SEO"
import Hero from "../components/hero"
import Pagination from "../components/pagination"
import Image from "../components/image"
import Newsletter from "../components/newsLetter"
import Sidebar from "../components/sidebar"
import GA from "../components/ga"
// import SidebarCart from "../components/sidebarCart"

// Styles
import styles from "./scss/blog.module.scss"
const BlogPage = ({ data }) => {
  const postsPerPage = 6
  const numberOfPages = Math.ceil(
    data.allContentfulBlogPost.totalCount / postsPerPage
  )

  return (
    <>
      <GA />
      <Layout>
        {/* <SidebarCart /> */}
        <SEO title="Brophy Brothers | Blog" />
        <Hero text="" image={data.contentfulBlogPageHero.image.file.url} />
        <section className={`section ${styles.blog}`}>
          <div className={styles.container}>
            <div className="banner">
              <h2 className="ribbon">
                <span className="top">&nbsp;</span>
                <span className="text">Blog</span>
                <span className="bottom">&nbsp;</span>
              </h2>
            </div>
            <ul>
              {data.allContentfulBlogPost.edges.map((edge) => {
                return (
                  <li key={edge.node.id}>
                    <Link to={`/blog/${slugify(edge.node.title)}`}>
                      <Image image={edge.node.image} />
                      <span>- {edge.node.category} -</span>
                      <h4>{edge.node.title}</h4>
                    </Link>
                  </li>
                )
              })}
            </ul>
            <Pagination currentPage={1} numberOfPages={numberOfPages} />
          </div>
        </section>
        <Newsletter />
        <Sidebar />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    contentfulBlogPageHero {
      text
      image {
        file {
          url
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      limit: 6
    ) {
      totalCount
      edges {
        node {
          id
          title
          image {
            title
            description
            fluid {
              src
              srcSet
              srcSetWebp
            }
          }
          category
        }
      }
    }
  }
`

export default BlogPage
