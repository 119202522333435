import React, { Component } from "react"
import { Link } from "gatsby"

import styles from "./scss/hero.module.scss"

class Hero extends Component {
  render() {
    const buttons = this.props.buttons
    const text = this.props.text
    const backgroundImage = {
      backgroundImage: `url(${this.props.image})`,
      height: "100%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }

    return (
      <section className={styles.image} style={backgroundImage}>
        <h1>{text}</h1>
        {buttons && (
          <div className={styles.buttons}>
            <Link className={styles.readMore} to="/about">
              About Us
            </Link>
            {/* <Link className={styles.viewProducts} to="/curbside">
              Order Now
            </Link> */}
            <a
              className={styles.viewProducts}
              href="mailto:orders@brophybrothers.net"
              target="_blank noopener noreferrer"
            >
              Order Now
            </a>
          </div>
        )}
      </section>
    )
  }
}
export default Hero
